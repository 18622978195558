<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <b-row>
                  <b-col><h6 class="mb-0">Kết quả đối soát</h6></b-col>
                  <b-col class="text-right">
                    <div>
                      <!--                      <a href="#/auto-reconcile/received/upload" class="btn btn-success">-->
                      <!--                        <i class="fa fa-plus" aria-hidden="true"></i>-->
                      <!--                        Import File-->
                      <!--                      </a>-->
                      <button
                        class="ml-2 btn btn-primary"
                        form=""
                        @click="$bvModal.show(modalId)"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                        Lấy file đối soát
                      </button>
                    </div>
                  </b-col>
                </b-row>
              </template>
              <div class="filter-transaction">
                <div>
                  <div class="row mb-8">
                    <div class="col-lg-6 mb-lg-6 mb-6">
                      <label>Ngày đối soát:</label>
                      <div
                        class="input-daterange input-group"
                        id="kt_datepicker"
                      >
                        <input
                          type="date"
                          v-model="filter.date_from"
                          class="form-control datatable-input"
                          name="start"
                          placeholder="Từ"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">
                            <i class="la la-ellipsis-h"></i>
                          </span>
                        </div>
                        <input
                          type="date"
                          v-model="filter.date_to"
                          class="form-control datatable-input"
                          name="end"
                          placeholder="Đến"
                        />
                      </div>
                    </div>
                    <div class="col-lg-2 mb-lg-3mb-2">
                      <label>Hệ thống:</label>
                      <select v-model="filter.system" class="form-control">
                        <option value="">Tất cả</option>
                        <option
                          v-for="item in systems"
                          v-bind:key="item.name"
                          :value="item.value"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-lg-2 mb-lg-3mb-2">
                      <label>Đối tác:</label>
                      <select v-model="filter.partner" class="form-control">
                        <option value="">Tất cả</option>
                        <option
                          v-for="item in partners"
                          v-bind:key="item.name"
                          :value="item.value"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-lg-2 mb-lg-3mb-2">
                      <label>Chọn MID:</label>
                      <select v-model="filter.mid_code" class="form-control">
                        <option value="">Tất cả</option>
                        <option
                          v-for="item in mid_codes"
                          v-bind:key="item.name"
                          :value="item.value"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row mt-8">
                    <div class="col-lg-12 text-right">
                      <button
                        class="btn btn-primary btn-primary--icon"
                        @click="handleFilter"
                      >
                        <span>
                          <i class="la la-search" aria-hidden="true"></i>
                          <span>Tìm kiếm</span>
                        </span>
                      </button>
                      <button
                        class="ml-2 btn btn-secondary btn-secondary--icon"
                        @click="cancelFilter"
                      >
                        <span>
                          <i class="fa fa-ban" aria-hidden="true"></i>
                          <span>Huỷ</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <b-table
                  :fields="fields"
                  :items="items"
                  details-td-class="datatable-cell"
                  hover
                  table-class="datatable-table"
                  tbody-tr-class="datatable-row"
                  thead-class="datatable-head"
                  thead-tr-class="datatable-row"
                >
                  <template #cell(stt)="data">
                    <div>
                      {{ data.index + 1 }}
                    </div>
                  </template>
                  <template #cell(project)="data">
                    <div v-if="data.item.project === 'WALLET'">Ví</div>
                    <div v-else-if="data.item.project === 'PAYGATE'">Cổng</div>
                    <div v-else>Chưa có</div>
                  </template>
                  <template #cell(type)="data">
                    {{
                      data.item.file_id_received
                        ? "Thành công (TC)"
                        : `Thiếu giao dịch ${data.item.fund}`
                    }}
                  </template>
                  <template #cell(action)="data">
                    <a :href="`${data.value.detail}`" target="_blank"
                      >Chi tiết</a
                    >
                    <br />
                    <a
                      v-if="data.item.file_path"
                      href="#"
                      @click="download(data.item.file_path)"
                      >Download</a
                    >
                    <br />
                    <a
                      v-if="data.item.file_id_received"
                      href="#"
                      @click="updateTransaction(data.item.file_id_received)"
                      >Cập nhật</a
                    >
                  </template>
                </b-table>
                <div class="mt-3">
                  <b-pagination-nav
                    v-model="currentPage"
                    :link-gen="createLinkPage"
                    :number-of-pages="paginate.totalPage"
                    use-router
                    first-number
                    last-number
                    align="right"
                  ></b-pagination-nav>
                </div>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
    <b-modal :id="modalId" title="Lấy file đối soát">
      <br />
      <div class="d-block">
        <div class="mb-8">
          <div class="mb-2">
            <label>Hệ thống:</label>
            <select v-model="get_file.system" class="form-control">
              <option
                v-for="item in systems"
                v-bind:key="item.name"
                :value="item.value"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="mb-2">
            <label>Đối tác:</label>
            <select v-model="get_file.partner" class="form-control">
              <option v-if="get_file.system === 'WALLET'" value="VPB">
                VPB
              </option>
              <option v-if="get_file.system === 'WALLET'" value="MB">MB</option>
              <option v-if="get_file.system === 'WALLET'" value="BVB">
                BVB
              </option>
              <option v-if="get_file.system === 'WALLET'" value="MSB">
                MSB
              </option>
              <option v-if="get_file.system === 'PAYGATE'" value="NAPAS">
                NAPAS
              </option>
              <option v-if="get_file.system === 'PAYGATE'" value="MB">
                MB
              </option>
              <option v-if="get_file.system === 'PAYGATE'" value="BIDV">
                BIDV
              </option>
            </select>
          </div>
          <div class="mb-6">
            <label>Ngày giao dịch:</label>
            <div class="input-daterange input-group" id="kt_datepicker">
              <input
                type="date"
                v-model="get_file.date_reconcile"
                class="form-control datatable-input"
                name="start"
              />
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="danger" @click="$bvModal.hide(modalId)">
          Hủy
        </b-button>
        <b-button size="sm" variant="success" @click="getFileReceived()">
          Lấy file
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "@/core/mixins/common";
import RepositoryFactory from "@/core/repositories/repositoryFactory";

const CmsRepository = RepositoryFactory.get("cms");

export default {
  name: "Reconcile",
  mixins: [Common],
  components: {},
  data() {
    return {
      filter: {
        date_from: null,
        date_to: null,
        mid_code: "",
        partner: "",
        system: "",
      },
      get_file: {
        date_reconcile: null,
        partner: null,
        system: "WALLET",
      },
      partners: [
        { value: "NAPAS", name: "NAPAS" },
        { value: "MB", name: "MB" },
        { value: "VPB", name: "VPB" },
        { value: "BIDV", name: "BIDV" },
        { value: "VCB", name: "VCB" },
        { value: "ONEPAY", name: "ONEPAY" },
        { value: "9PAY", name: "9PAY" },
        { value: "PAYGATE", name: "PAYGATE" },
        { value: "VCCB", name: "BVB" },
        { value: "MSB", name: "MSB" },
        { value: "VPB_CYBS", name: "VPB_CYBS" },
        { value: "NAPAS_CASHOUT", name: "NAPAS_CASHOUT" },
        { value: "STB_CYBS", name: "STB_CYBS" },
      ],
      systems: [
        { value: "WALLET", name: "Ví điện tử" },
        { value: "PAYGATE", name: "Cổng thanh toán" },
      ],
      mid_codes: [
        { value: "MID_Napas_1", name: "MID_Napas_1" },
        { value: "MID_Napas_2", name: "MID_Napas_2" },
        { value: "MID_Napas_3", name: "MID_Napas_3" },
      ],
      modalId: "get-file",
      id: null,
      fields: [
        {
          key: "stt",
          label: "STT",
        },
        {
          key: "project",
          label: "Hệ thống",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "date",
          label: "Ngày đối soát",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "type",
          label: "Loại file",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "file_name",
          label: "Tên file",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "fund",
          label: "Tên đối tác",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "mid_code",
          label: "MID",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "record_total",
          label: "Tổng giao dịch đối tác gửi",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "date_file",
          label: "Thời điểm nhận file",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "correct_total",
          label: "Số giao dịch khớp",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "incorrect_total",
          label: "Số giao dịch sai lệch",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "action",
          label: "Thao tác",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      items: [],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
  },
  watch: {
    currentPage(newPage, oldPage) {
      if (newPage !== oldPage) {
        this.getList();
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Kết quả đối soát" }]);
    this.filter = this.$route.query;

    this.cancelFilter();
  },
  methods: {
    async download(filePath) {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CmsRepository.downloadFile(filePath);
        this.$bus.$emit("show-loading", false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filePath);
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.notifyAlert("success", "Đang tiến hành tải xuống file của bạn");
      } catch (e) {
        this.notifyAlert("warn", "File không tồn tại, vui lòng thử lại.");
        this.$bus.$emit("show-loading", false);
      }
    },
    async getFileReceived() {
      if (
        !this.get_file.date_reconcile ||
        !this.get_file.system ||
        !this.get_file.partner
      ) {
        this.notifyAlert("warn", "Vui lòng nhập đầy đủ thông tin");
        return;
      }
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CmsRepository.manualReconcile(this.get_file);
        this.$bus.$emit("show-loading", false);
        let body = response.data.data;
        if (body.status) {
          this.notifyAlert(
            "success",
            "Hệ thông đang thực hiện lấy file. Vui lòng chờ trong vài phút và kiểm tra tại Danh sách kết quả đối soát"
          );
          this.$bvModal.hide(this.modalId);
        } else {
          this.notifyAlert(
            "warn",
            "Có lỗi xảy ra khi lấy file, vui lòng thử lại"
          );
        }
      } catch (e) {
        this.notifyAlert("warn", "Có lỗi xảy ra, vui lòng thử lại.");
        this.$bus.$emit("show-loading", false);
      }
    },
    async updateTransaction(id) {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await CmsRepository.reSyncReceived({
          file_id: id,
        });
        this.$bus.$emit("show-loading", false);
        let body = response.data.data;
        if (parseInt(body.number) > 0) {
          alert("Kết quả đối soát đã được cập nhật");
          location.reload();
        } else {
          alert("Kết quả đối soát không thay đổi");
        }
      } catch (e) {
        this.notifyAlert("warn", "Có lỗi xảy ra, vui lòng thử lại.");
        this.$bus.$emit("show-loading", false);
      }
    },
    async getList() {
      try {
        this.$bus.$emit("show-loading", true);
        let params = this.filter;
        params.page = this.currentPage;
        params.limit = this.paginate.limit;
        let response = await CmsRepository.getReceivedAggregate(params);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        this.items = body.data.data;

        if (this.items.length) {
          this.items.forEach((value) => {
            value["action"] = {
              detail: `#/auto-reconcile/received/list/${value.file_id_received}?partner=${value.fund}&date_from=${value.date}&is_reconciled=${value.is_reconciled}`,
            };
          });
        }

        this.paginate.total = body.data.total;
        this.paginate.totalPage = body.data.last_page;
      } catch (e) {
        this.notifyAlert("warn", "Có lỗi xảy ra, vui lòng thử lại.");
        this.$bus.$emit("show-loading", false);
      }
    },
    handleFilter() {
      // router
      //   .push({
      //     path: "/auto-reconcile/received/aggregate",
      //     query: this.filter,
      //   })
      //   .catch(() => {});
      this.getList();
    },
    cancelFilter() {
      this.filter = {
        date_from: null,
        date_to: null,
        mid_code: "",
        partner: "",
        system: "",
      };
      this.getList();
    },
  },
};
</script>
